<template>
  <div>
    <div class="title">
      <div class="login">注册</div>
      <div class="register" @click="$router.push({ path: 'login' })">
        直接登录
      </div>
    </div>
    <div class="content">
      <div class="iphoneNumber">
        <van-field
          style="background-color: #f5f5f5; border-radius: 0.6rem"
          v-model="user"
          :rules="[
            { required: true, message: '请填写您的手机号码！' },
            { pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！' }
          ]"
          type="tel"
          center
          clearable
          placeholder="请输入手机号码"
          label-width="250px"
        >
        </van-field>
      </div>
      <div class="code">
        <van-field
          style="background-color: #f5f5f5; border-radius: 0.6rem"
          v-model="code"
          center
          clearable
          placeholder="请输入短信验证码"
          label-width="250px"
        >
          <template #right-icon>
            <div v-show="codeShow" class="VerificationCode" @click="getCode">
              获取验证码
            </div>
            <div v-show="!codeShow" class="VerificationCode">
              {{ yzmTitle }}
            </div>
          </template>
        </van-field>
      </div>
      <div class="InvitationCode">
        <van-field
          style="background-color: #f5f5f5; border-radius: 0.6rem"
          v-model="InvitationCode"
          center
          clearable
          placeholder="请输入您的邀请码(必填)"
          label-width="250px"
        >
        </van-field>
      </div>
      <div class="submit" @click="submit">下一步</div>
    </div>
  </div>
</template>

<script>
import { getCode, register } from "@/api/login";
export default {
  data() {
    return {
      InvitationCode: "",
      user: "",
      code: "",
      yzmTitle: "",
      codeShow: true
    };
  },
  methods: {
    getCode() {
      let phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;
      if (!phonereg.test(this.user)) {
        this.$toast("手机号格式不正确");
        return;
      }
      if (!this.user) {
        this.$toast("请输入手机号码");
        return;
      }

      let params = {
        mobile: this.user
      };
      getCode(params).then(res => {
        console.log(res);
        if (res.data.code == 200) {
          let time = 60;
          let timer = setInterval(() => {
            if (time == 0) {
              clearInterval(timer);
              this.codeShow = true;
            } else {
              this.codeShow = false;
              this.yzmTitle = time + "秒后重试";
              time--;
            }
          }, 1000);
          this.$toast("验证码获取成功");
        } else {
          this.$toast(res.data.message);
        }
      });
    },
    submit() {
      let phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/;
      if (!phonereg.test(this.user)) {
        this.$toast("手机号格式不正确");
        return;
      }

      if (!this.user) {
        this.$toast("请输入手机号");
      }
      if (!this.InvitationCode || !this.code) {
        this.$toast("请填写短信验证码或邀请码");
      }
      let params = {
        mobile: this.user,
        invite_code: this.InvitationCode,
        keyCode: this.code
      };
      register(params).then(res => {
        console.log(res);
        if (res.data.code == 200) {
          let id = res.data.data.access_id;
          this.$store.commit("getRegisterId", id);
          this.$router.push({ path: "/FindPsw" });
        } else {
          this.$toast(res.data.message);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  height: 31px;
  font-size: 33px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  padding-top: 88px;
  text-align: center;
  .login {
    // text-align: center;
    font-size: 33px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
  }
  .register {
    position: absolute;
    right: 63px;
    top: 95px;
    font-size: 25px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
  }
}
.content {
  padding-top: 120px;
  margin: 0 65px 0 65px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  .iphoneNumber {
    width: 100%;
    height: 90px;
    background: #f5f5f5;
    border-radius: 45px;
    .inputPhoneNumber {
      height: 100%;
      margin-left: 40px;
    }
  }
  .code {
    width: 100%;
    height: 90px;
    background: #f5f5f5;
    border-radius: 45px;
    margin-top: 52px;
    .inputCode {
      height: 100%;
      margin-left: 40px;
    }
    .VerificationCode {
      width: 174px;
      height: 51px;
      border: 1px solid #d2d2d2;
      border-radius: 26px;

      font-size: 28px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #999999;
      text-align: center;
    }
  }
  .InvitationCode {
    margin-top: 52px;
    width: 100%;
    height: 90px;
    background: #f5f5f5;
    border-radius: 45px;
  }
  .submit {
    width: 100%;
    height: 90px;
    background: linear-gradient(-30deg, #323234, #464646);
    border-radius: 45px;
    line-height: 90px;
    text-align: center;
    color: #ffffff;
    margin-top: 52px;

    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
